'use strict';

angular.module('enervexSalesappApp').controller('QuotesCtrl', function($scope, $stateParams, Quote, Account, Job, QuotesService, User, Auth, Property, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.configs = Property.configs()
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	}).$promise.then(function(job){
		$scope.job = job;
		$scope.permissions = Auth.jobPermissions($scope.job, $scope.configs);
	});
	getQuotes();
	function getQuotes() {
		QuotesService.fetchQuotes().$promise.then(function(quotes){
			$scope.quotes = _.sortBy(quotes, function(quote) {
				return quote.name && quote.name.toLowerCase()
			})
		});
	}
});
